<template>
    <div class="modal-message">
        <!-- Modal -->
        <div class="modal fade" id="modalMessage" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true" @click="$emit('handle:click')">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="sz-heading--large modal-title fs-5" id="exampleModalLabel"> {{ title }}</h5>
                        <div class="close-btn">
                            <button type="button" class="close btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('handle:click')">
                                <img class="" :src="closeurl" title="Fechar" alt="Fechar" />
                            </button>
                        </div>
                    </div>
                    <div class="modal-body" v-if="messageHtml">
                        <p>Lamentamos que seu pedido não tenha sido realizado. Pedimos que verifique as seguintes informações e tente novamente.</p>
                        <ul>
                            <li>Dados de Entrega</li>
                            <li>Endereço de Cobrança</li>
                            <li>CPF/CNPJ</li>
                            <li>Dados de Pagamento</li>
                        </ul>
                       <!--trocar--> <p>Se você tiver mais problemas, fale conosco no Whatsapp <a :href="whatslink">({{ whatsnumber }})</a></p>
                    </div>
                    <div class="modal-body" v-else>{{ message }}</div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-primary btn-try-again" @click="$emit('handle:click')">Tentar Novamente</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props:['title', 'message', 'messageHtml'],
        created() {
            // handle close modal
            $('body').on('click', 'button.close.btn-close, button.btn-try-again, #modalMessage', function () {
                $('#modalMessage').modal('hide');
                window.scrollTo(0,0);
            });
        },
        computed: {
            ...mapGetters('checkout', {
                closeurl: 'closeurl'
            }),
            ...mapGetters('account', {
                whatslink: 'whatslink',
                whatsnumber: 'whatsnumber'
            }),
        }
    };
</script>