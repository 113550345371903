var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    attrs: {
      modalId: "alertRemoveCouponModal",
      size: "md",
      showCloseButton: true,
      isStatic: true,
      startOpen: false,
    },
    on: { "hande:close": _vm.handleClose },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v("\n       Remover cupom?\n    ")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("p", [
              _vm._v(
                "Tem certeza que deseja remover o seguinte cupom do carrinho?"
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "coupon-to-remove" }, [
              _vm._v(_vm._s(_vm.coupon.couponCode)),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                attrs: { type: "button", "data-dismiss": "modal" },
              },
              [_vm._v("Cancelar")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "btn btn-primary delete-coupon-confirmation-modal-btn",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: {
                  click: function ($event) {
                    return _vm.removeCoupon()
                  },
                },
              },
              [_vm._v("\n                Sim\n        ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }