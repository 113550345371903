var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$root.$refs.Checkout.activeStep !== 1
    ? _c("div", { staticClass: "checkout-summary" }, [
        _c("div", { staticClass: "toggle-details__container" }, [
          _c(
            "button",
            {
              staticClass: "toggle-details-btn",
              on: { click: _vm.toggleDetails },
            },
            [_c("span", [_vm._v("Detalhes do Pedido")])]
          ),
          _vm._v(" "),
          _c("i", {
            staticClass: "icon-chevron-right",
            class: { active: _vm.showDetails },
          }),
        ]),
        _vm._v(" "),
        _vm.showDetails
          ? _c("div", { staticClass: "order-details" }, [
              _c("div", { staticClass: "priceResume" }, [
                _c("p", [
                  _vm._v("Subtotal"),
                  _c("span", [
                    _vm._v(_vm._s(_vm.totals.totalWithoutDiscount.formatted)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "leading-lines coupomCheckout" }, [
                  _c(
                    "div",
                    { staticClass: "coupons-and-promos" },
                    [_c("coupon-display")],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.totals.grandTotalDiscount.formatted
                  ? _c("p", [
                      _vm._v("Desconto"),
                      _c("span", [
                        _vm._v(
                          " - " +
                            _vm._s(_vm.totals.grandTotalDiscount.formatted)
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("p", [
                  _vm._v("Frete"),
                  _c(
                    "span",
                    {
                      staticClass: "shipping-total-cost",
                      class: {
                        "text-green":
                          _vm.totals.totalShippingCost.formatted.trim() ===
                          "Grátis",
                      },
                    },
                    [
                      _vm._v(
                        "\n                      " +
                          _vm._s(_vm.totals.totalShippingCost.formatted) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "priceResume total" }, [
          _c("p", { staticClass: "priceTotals" }, [
            _vm._v("Total"),
            _c("span", [_vm._v(_vm._s(_vm.totals.grandTotal.formatted))]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showBack,
                expression: "showBack",
              },
            ],
            staticClass: "summary-back-home-btn",
            attrs: { href: _vm.homeUrl },
          },
          [_vm._v("Continuar Comprando")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showCta,
                expression: "showCta",
              },
            ],
            staticClass: "continue-btn",
            attrs: { disabled: _vm.disabled },
            on: { click: _vm.handleClick },
          },
          [
            _vm._v(
              "\n    " +
                _vm._s(_vm.fineshed ? "Finalizar Compra" : "Continuar") +
                "\n    "
            ),
            _c("i", {
              staticClass: "icon-chevron-right",
              staticStyle: { "margin-left": "10px", "font-size": "25px" },
            }),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }