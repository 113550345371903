var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "appCheckout hide",
      attrs: {
        id: "checkout-main",
        "data-checkout-stage":
          _vm.activeStep === 2
            ? "shipping"
            : _vm.activeStep === 3
            ? "payment"
            : "placeOrder",
      },
    },
    [
      _c("stepper", { attrs: { activeStep: _vm.activeStep } }),
      _vm._v(" "),
      _vm.activeStep !== 1
        ? _c("handle-step", { on: { "handle:click": _vm.backStep } })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeStep == 1
        ? _c("div", [_c("quotation-step")], 1)
        : _c("div", [
            _c(
              "div",
              { staticClass: "checkoutColumns" },
              [
                _c("shipping-step", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeStep === 2,
                      expression: "activeStep===2",
                    },
                  ],
                  class: { current: _vm.activeStep === 2 },
                  on: { "next:step": _vm.nextStep },
                }),
                _vm._v(" "),
                _c("payment-step", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeStep === 3,
                      expression: "activeStep===3",
                    },
                  ],
                  class: { current: _vm.activeStep === 3 },
                  on: { "back:step": _vm.backStep },
                }),
                _vm._v(" "),
                _c("review-step", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeStep === 4,
                      expression: "activeStep===4",
                    },
                  ],
                  class: { current: _vm.activeStep === 4 },
                  on: { "back:step": _vm.backStep },
                }),
                _vm._v(" "),
                _c("checkout-review"),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "quotationSelected" },
              [
                _c("h2", { staticClass: "sz-heading--large" }, [
                  _vm._v("DETALHES DO PEDIDO"),
                ]),
                _vm._v(" "),
                _c("quotation-selected"),
              ],
              1
            ),
          ]),
      _vm._v(" "),
      _c("checkout-summary", { attrs: { enabled: true } }),
      _vm._v(" "),
      _c("modal-message", {
        attrs: {
          title: "Erro ao finalizar seu pedido!",
          message: _vm.errorMessage,
          messageHtml: _vm.errorMessageHtml,
        },
        on: { "handle:click": _vm.handleClick },
      }),
      _vm._v(" "),
      _c("remove-coupon-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }