
<template>
  <div class="box_wrap" v-if="parsedItems.length">
    <h2 class="title">{{ currentTitle }}</h2>

    <slick class="mainItensCarousel pdpSlider" ref="main" :options="mainItensCarousel">
      <div class="slide" v-for="item in parsedItems" :key="item.id">
        <product-card
          :product="item"
          :showQnt="false"
          :showBtn="true"
          :urlAddCart="urlAddCart"
          :urlUpdateQnt="urlUpdateQnt"
          :urlRemoveProduct="urlRemoveProduct"
        />
      </div>
    </slick>
  </div>
</template>

<script>
import ProductCard from './ProductCard';
import Slick from 'vue-slick';

export default {
  props: ['title', 'titleMobile', 'recommendations', 'urlAddCart', 'urlUpdateQnt', 'urlRemoveProduct' ],
  components: {
    ProductCard,
    Slick
  },
  data() {
      return {
          parsedItems: null,
          currentTitle: this.title,
          mainItensCarousel: {
              infinite: false,
              slidesToShow: 3,
              slidesToScroll: 1,
              arrows: true,
              prevArrow: '<span class="slick-prev"></span>',
              nextArrow: '<span class="slick-next"></span>',
              autoplay: false,
              lazyLoad: 'progressive',
              responsive: [
                  {
                      breakpoint: 991,
                      settings: {
                          slidesToShow: 1.5,
                          dots: false,
                          mobileFirst: true,
                          vertical: false,
                      }
                  },
                  {
                      breakpoint: 576,
                      settings: {
                          slidesToShow: 2,
                          dots: false,
                          vertical: false,
                      }
                  }
              ]
          }
      };
  },
  created() {
    this.parsedItems = JSON.parse(this.recommendations);
    window.addEventListener('resize', this.updateTitle);
    this.updateTitle();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateTitle);
  },
  methods: {
    updateTitle() {
      this.currentTitle = window.innerWidth > 768 ? this.title : this.titleMobile;
    }
  }
}
</script>

<style>
  .mainItensCarousel{
    width: 100%;
  }
  .mainItensCarousel .slick-track{
    display: flex;
    column-gap: 20px;
  }
  @media (max-width: 576px){
    .mainItensCarousel .slick-track{
    column-gap: 5px;
  }
}
</style>