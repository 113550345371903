<template>
    <modal
        modalId="requestPasswordResetModal"
        size="md"
        :showCloseButton="true"
        :isStatic="true"
        :startOpen="false"
        @hande:close="handleClose"
    >
        <template v-slot:title>

            <template v-if="emailSend">
                Solicitação para redefinir sua senha recebida
            </template>
            <template v-else>
                Esqueceu sua senha
            </template>

        </template>

        <template v-slot:body>

            <template v-if="emailSend">
                Enviamos um e-mail com as informações possíveis para redefinir sua senha. O e-mail pode levar alguns minutos para chegar à sua conta. Por favor, verifique seu lixo eletrônico para garantir que você receba.
            </template>
            <template v-else>
                <div class="alert alert-danger" role="alert" v-show="resetErr">
                    {{ resetErr }}
                </div>

                <form @submit.prevent="onSubmit" class="reset-password-form" method="POST" name="email-form" id="email-form">
                    <div class="request-password-body">
                        <p class="sz-text--medium">
                            Insira o endereço de e-mail da sua conta para receber um e-mail e redefinir sua senha.
                        </p>
                        <div class="form-group required">
                            <label for="reset-password-email" class="form-control-label">
                                E-mail
                            </label>
                            <input
                                id="reset-password-email"
                                type="email"
                                v-model="email"
                                class="form-control"
                                :class="{'error': errors.email}"
                                name="loginEmail"
                                aria-describedby="emailHelp"
                                placeholder="Digite seu e-mail">
                            <div v-show="errors.email" class="invalid-feedback" role="alert" id="emailHelp">{{ errors.email }}</div>
                        </div>
                    </div>
                    <div class="send-email-btn">
                        <button
                            type="button"
                            id="submitEmailButton"
                            class="login-option btn js-reset-password"
                            @click="resetPassword()"
                        >
                            Enviar
                        </button>
                    </div>
                </form>
            </template>
        </template>

    </modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Modal from '../Modal';
    import routes from '../../../api/routes';

    export default {
        components: {
            Modal
        },

        data() {
            return {
                email: '',
                errors: {},
                staticUrl: routes.urlStatic,
            }
        },
        computed: {
            ...mapGetters('account', {
                resetErr: 'resetErr',
                emailSend: 'emailSend',
            }),

            ...mapGetters('checkout', {
                csrf: 'csrf'
            })
        },
        methods: {
            handleClose() {
                this.$store.commit('account/setEmailSend', false);
                this.$store.commit('account/setShowResetForm', false);
                this.$store.commit('checkout/setIsShareQuotation', true);
                $('#requestPasswordResetModal').modal('hide');
            },

            resetPassword(){
                if(this.validateForm()){
                    this.$store.dispatch('account/passwordResetDialogForm', {loginEmail: this.email, csrf_token: this.csrf.token});
                }
            },

            validateForm() {
                this.errors = {};

                if (this.email === '') {
                    this.$set(this.errors, 'email', 'Campo obrigatório.');
                } else if (!this.isValidEmail(this.email)) {
                    this.$set(this.errors, 'email', 'E-mail inválido.');
                }

                return Object.values(this.errors).every(error => !error);
            },

            isValidEmail(email) {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailPattern.test(email);
            }

        }
    }
</script>

<style scoped>
    .invalid-feedback{
        display: block !important;
    }
    .error{
        border-color: #c00 !important;
    }
    .alert{
        font-size: 14px;
    }
</style>
