<template>
    <modal
        modalId="modalShareInfo"
        size="md"
        :showCloseButton="false"
        :isStatic="true"
        :startOpen="isShareQuotation ? true : false"
        @hande:close="handleClose"
    >
        <template v-slot:title>
            <h5 class="modal-title bold sz-heading--medium pb-0" id="modalShareInfoLabel">Bem-vindo ao Suprijá!</h5>
        </template>
        <template v-slot:body>
            <p v-if="addressModal.city && addressModal.postalCode">
                A cotação que você recebeu contém produtos de <b> {{addressModal.city}} | {{addressModal.postalCode}}</b>.
            </p>

            <template v-if="!logged">
                <p><b>Acesse a sua conta para concluir o pedido!</b></p>

                <div id="showForm" v-if="showForm">
                    <login-form></login-form>
                    <login-oauth></login-oauth>
                </div>
            </template>
        </template>

        <template v-slot:footer>

            <button style="width: 100%;" @click="handleClose" class="btn button button-secondary" v-show="logged">Visualizar minha cotação</button>

            <template v-if="!logged">
                <div class="cta-body" v-if="!showForm">
                    <button type="button" class="login-option btn" @click="showFormLogin">Fazer login</button>
                    <button type="button" class="register-option btn" data-toggle="modal" data-target="#registerCheckout" @click="handleClose">
                        Cadastrar-se
                    </button>
                </div>
                <div class="login-divider"><span>ou</span></div>
                <button  v-if="showForm" type="button" class="register-option btn" data-toggle="modal" data-target="#registerCheckout" @click="handleClose">
                    Cadastrar-se
                </button>
                <button type="button" class="btn-guest" @click="handleClose">
                    Continuar como convidado
                </button>
            </template>
        </template>
    </modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Modal from '../Modal';
    import LoginOauth from '../account/LoginOauth';
    import LoginForm from '../account/LoginForm';
    import routes from '../../../api/routes';

    export default {
        components: {
            Modal,
            LoginForm,
            LoginOauth
        },

        data() {
            return {
                showForm: false,
                errors: {},
                staticUrl: routes.urlStatic,
                LoginUrl: routes.login.show
            }
        },

        computed: {
            ...mapGetters('checkout', {
                isShareQuotation: 'isShareQuotation',
                addressModal: 'addressModal',
                customerHasProfile: 'customerHasProfile',
            }),

            ...mapGetters('account', {
                logged: 'logged',
            })
        },

        methods: {
            handleClose() {
                this.$store.commit('checkout/setIsShareQuotation', false)
                $('#modalShareInfo').modal('hide');
            },

            showFormLogin(){
                this.showForm = !this.showForm;
            }
        }
    }
</script>
