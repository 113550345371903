<template>

    <div class="form-nav billing-nav payment-information" v-if="billing && billing.payment.applicablePaymentMethods.length > 0">

        <ul class="nav nav-fill payment-options">
            <li class="nav-item" :class="paymentOption.ID==='IUGU_CREDIT_CARD' && maxAttemptError ? 'disabled' : ''" v-for="paymentOption in uniquePaymentOptions" :data-method-id="paymentOption.ID">
                <a class="nav-link optionRadio" :class="paymentSelected === paymentOption.ID ? 'active' : ''" type="button"
                    :name="'method__' + paymentOption.ID" @click="updateSelectedPaymentMethod(paymentOption.ID)">
                    <i :class="getIcon(paymentOption.ID)"></i> {{ paymentOption.name }}
                </a>
            </li>
        </ul>


        <div class="credit-card-selection-new">
            <div class="tab-content">

                <template v-if="paymentSelected === 'IUGU_PIX'">
                    <payment-options-content>
                        <ul class="pixPayment__Description">
                            <li><i class="icon-clock"></i>O código Pix gerado após a finalização do pedido será válido
                                por 48 horas</li>
                        </ul>
                    </payment-options-content>
                    <p class="pixPayment__exinfo">Aprovação imediata</p>
                </template>

                <template v-if="paymentSelected === 'BANK_SLIP'">
                    <payment-options-content>
                        <div class="d-flex flex-column">
                            <label class="d-flex flex-column">
                                <div>
                                    <input type="radio" value="IUGU_BANK_SLIP" v-model="bankSlipType">
                                    <b>Boleto à vista</b>
                                </div>
                                Vencimento em 2 dias úteis. Aprovação em 1 a 2 dias úteis após o pagamento.
                            </label>
                            <label class="d-flex flex-column mt-3" v-if="bankSlipPostPaidAvailable">
                                <div>
                                    <input type="radio" value="BANK_SLIP_POSTPAID" v-model="bankSlipType">
                                    <b>Crédito Suprijá</b> <img :src="`${staticPath}icon2.png`" alt="bank_slip_postpaid_icon" title="Bank Slip Postpaid Icon" width="20"/>
                                </div>
                                Boleto faturado com vencimento em 30 dias. Pedido sujeito a aprovação de crédito. Confirmação em até 48h.
                            </label>
                        </div>
                    </payment-options-content>
                </template>

                <template v-if="paymentSelected === 'IUGU_CREDIT_CARD'">
                    <fieldset class="paymentFormBox">
                        <!--- Hidden input for credit card type --->
                        <input type="hidden" class="form-control" id="cardType"
                            :name="forms.billingForm.creditCardFields.cardType.htmlName"
                            v-model="creditCardModel[forms.billingForm.creditCardFields.cardType.htmlName]">

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group" :class="[forms.billingForm.creditCardFields.cardOwner.mandatory === true ? 'required' : '',
                                forms.billingForm.creditCardFields.cardOwner.htmlName]">
                                    <div class="card-owner-wrapper">
                                        <input type="text" class="form-control cardOwner checkoutInputs"
                                            :class="{ 'error': errorsCreditCard.dwfrm_billing_creditCardFields_cardOwner }"
                                            placeholder="Nome no cartão" id="cardOwner"
                                            :required="forms.billingForm.creditCardFields.cardOwner.mandatory"
                                            :name="forms.billingForm.creditCardFields.cardOwner.htmlName"
                                            :maxlength="forms.billingForm.creditCardFields.cardOwner.maxLength"
                                            v-model="creditCardModel[forms.billingForm.creditCardFields.cardOwner.htmlName]">
                                        <div v-show="errorsCreditCard[forms.billingForm.creditCardFields.cardOwner.htmlName]"
                                            class="error-message" id="cardOwnerInvalidMessage">
                                            {{ errorsCreditCard[forms.billingForm.creditCardFields.cardOwner.htmlName]
                                            }}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group" :class="[forms.billingForm.creditCardFields.cardNumber.mandatory === true ? 'required' : '',
                                forms.billingForm.creditCardFields.cardNumber.htmlName]">
                                    <div class="card-number-wrapper">
                                        <input type="text" class="form-control cardNumber checkoutInputs"
                                            :class="{ 'error': errorsCreditCard.dwfrm_billing_creditCardFields_cardNumber }"
                                            placeholder="Número do cartão" id="cardNumber"
                                            inputmode="numeric"
                                            :required="forms.billingForm.creditCardFields.cardNumber.mandatory"
                                            :name="forms.billingForm.creditCardFields.cardNumber.htmlName"
                                            v-model="creditCardModel[forms.billingForm.creditCardFields.cardNumber.htmlName]"
                                            :input="isAmex"
                                            v-mask="cardTypeMask" autocomplete="cc-number">
                                        <div v-show="errorsCreditCard[forms.billingForm.creditCardFields.cardNumber.htmlName]"
                                            class="error-message" id="cardNumberInvalidMessage">
                                            {{ errorsCreditCard[forms.billingForm.creditCardFields.cardNumber.htmlName]
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <div class="form-group" :class="[forms.billingForm.creditCardFields.expirationMonth.mandatory === true ? 'required' : '',
                                forms.billingForm.creditCardFields.expirationMonth.htmlName]">
                                    <select class="form-control expirationMonth custom-select checkoutInputs"
                                        :class="{ 'error': errorsCreditCard[forms.billingForm.creditCardFields.cardOwner.htmlName] }"
                                        id="expirationMonth"
                                        v-model="creditCardModel['dwfrm_billing_creditCardFields_expirationMonth']"
                                        name="dwfrm_billing_creditCardFields_expirationMonth">
                                        <option
                                            :value="forms.billingForm.creditCardFields.expirationMonth.options[0].htmlValue">
                                            {{ forms.billingForm.creditCardFields.expirationMonth.options[0].label }}
                                        </option>
                                        <template
                                            v-for="month in forms.billingForm.creditCardFields.expirationMonth.options">
                                            <option v-if="month.value" :id="month.id" :value="month.htmlValue"
                                                :key="month.value">
                                                {{ month.label }}
                                            </option>
                                        </template>
                                    </select>
                                    <div v-show="errorsCreditCard[forms.billingForm.creditCardFields.cardOwner.htmlName]"
                                        class="error-message" id="cardNumberInvalidMessage">{{
                                            errorsCreditCard[forms.billingForm.creditCardFields.cardOwner.htmlName] }}</div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group" :class="[forms.billingForm.creditCardFields.expirationYear.mandatory === true ? 'required' : '',
                                forms.billingForm.creditCardFields.expirationYear.htmlName]">

                                    <select class="form-control expirationYear custom-select checkoutInputs"
                                        :class="{ 'error': errorsCreditCard[forms.billingForm.creditCardFields.cardOwner.htmlName] }"
                                        id="expirationYear"
                                        v-model="creditCardModel['dwfrm_billing_creditCardFields_expirationYear']"
                                        name="dwfrm_billing_creditCardFields_expirationYear">
                                        <option
                                            :value="forms.billingForm.creditCardFields.expirationYear.options[0].htmlValue">
                                            {{ forms.billingForm.creditCardFields.expirationYear.options[0].label }}
                                        </option>

                                        <template v-for="optionYear in getExpirationYears">
                                            <option :key="optionYear" :value="optionYear">
                                                {{ optionYear }}
                                            </option>
                                        </template>
                                    </select>
                                    <div v-show="errorsCreditCard[forms.billingForm.creditCardFields.cardOwner.htmlName]"
                                        class="error-message" id="cardNumberInvalidMessage">{{
                                            errorsCreditCard[forms.billingForm.creditCardFields.cardOwner.htmlName] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group" :class="[forms.billingForm.creditCardFields.securityCode.mandatory === true ? 'required' : '',
                                forms.billingForm.creditCardFields.securityCode.htmlName]">
                                    <input type="text" placeholder="CVV"
                                        class="form-control securityCode checkoutInputs"
                                        :class="{ 'error': errorsCreditCard.dwfrm_billing_creditCardFields_securityCode }"
                                        id="securityCode"
                                        inputmode="numeric"
                                        :required="forms.billingForm.creditCardFields.securityCode.mandatory"
                                        :name="forms.billingForm.creditCardFields.securityCode.htmlName"
                                        :maxLength="forms.billingForm.creditCardFields.securityCode.maxLength"
                                        v-mask="securityCodeMask"
                                        v-model="creditCardModel[forms.billingForm.creditCardFields.securityCode.htmlName]">
                                    <div v-show="errorsCreditCard[forms.billingForm.creditCardFields.securityCode.htmlName]"
                                        class="error-message" id="cardNumberInvalidMessage">{{
                                            errorsCreditCard[forms.billingForm.creditCardFields.securityCode.htmlName] }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <template v-if="customer.registeredUser">
                                    <!--
                                            <div class="save-credit-card custom-control custom-checkbox ">
                                                <input
                                                type="checkbox"
                                                class="custom-control-input"
                                                id="saveCreditCard"
                                                :name="forms.billingForm.creditCardFields.saveCard.htmlName"
                                                value="true"
                                                checked>
                                                <label
                                                    class="custom-control-label"
                                                    for="saveCreditCard">
                                                    {{ forms.billingForm.creditCardFields.saveCard.label }}
                                                </label>
                                            </div>
                                        -->
                                </template>
                            </div>
                        </div>
                        <!--  TODO: create compotent to credit card
                                <payment-options-credit-card></payment-options-credit-card>
                            -->
                    </fieldset>
                </template>
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';
import PaymentOptionsContent from './PaymentOptionsContent';
import routes from '../../../api/routes'

export default {

    components: {
        PaymentOptionsContent,
        //PaymentOptionsCreditCard
    },

    props: {
        paymentMethodModel: {
            default: {},
            type: Object
        },
        creditCardModel: {
            default: {},
            type: Object
        },
        errorsCreditCard: {
            default: {},
            type: Object
        }
    },

    computed: {
        ...mapGetters('checkout', {
            addressModel: 'addressModel',
            customer: 'customer',
            billing: 'billing',
            forms: 'forms',
            maxAttemptError: 'maxAttemptError',
            maxAttemptTimeout: 'maxAttemptTimeout',
        }),
        
        isAmex() {
            // Verifica se o cartão começa com 34 ou 37 para ser Amex
            const creditCardType = this.creditCardModel.dwfrm_billing_creditCardFields_cardNumber;
            const cleanNumber = creditCardType.replace(/\D/g, '');
            const isAmex = /^3[47]/.test(cleanNumber);
            return this.creditCardAmex = isAmex;
        },

        cardTypeMask() {
            return this.creditCardAmex
                ? '#### ###### #####'
                : '#### #### #### ####';
        },

        securityCodeMask() {
            return this.creditCardAmex? '####' : '###';
        },

        getExpirationYears() {
            const currentYear = new Date().getFullYear();
            const creditCardExpirationYears = [];

            for (let i = 0; i < 10; i++) {
                creditCardExpirationYears.push((currentYear + i).toString());
            }

            return creditCardExpirationYears;
        },
        uniquePaymentOptions() {
            return this.billing.payment.applicablePaymentMethods.map(method => {
                if (method.ID === 'IUGU_BANK_SLIP' || method.ID === 'BANK_SLIP_POSTPAID') {
                    this.bankSlipPostPaidAvailable = (method.ID === 'BANK_SLIP_POSTPAID');
                    return { ID: 'BANK_SLIP', name: 'Boleto' };
                }
                return method;
            }).filter((method, index, self) => index === self.findIndex((m) => m.ID === method.ID));
        }
    },

    data() {
        return {
            paymentSelected: '',
            bankSlipType: 'IUGU_BANK_SLIP',
            togglePaymentMethod: false,
            staticPath: routes.urlStatic,
            bankSlipPostPaidAvailable: false,
            creditCardAmex : false
        }
    },

    mounted() {
        const selectMonth = document.querySelector('select[name="dwfrm_billing_creditCardFields_expirationMonth"]');
        const selectYear = document.querySelector('select[name="dwfrm_billing_creditCardFields_expirationYear"]');

        //Fake set placeholder
        if (selectMonth && selectYear) {
            selectMonth.selectedIndex = 0;
            selectYear.selectedIndex = 0;
        }

        this.updateSelectedPaymentMethod('IUGU_PIX')
    },

    methods: {
        updateSelectedPaymentMethod(paymentOption) {
            this.paymentSelected = paymentOption;
            console.log("🚀 ~ updateSelectedPaymentMethod ~ paymentOption:", paymentOption)
            if (paymentOption === 'BANK_SLIP') {
                this.paymentMethodModel['dwfrm_billing_paymentMethod'] = this.bankSlipType;
            } else {
                this.paymentMethodModel['dwfrm_billing_paymentMethod'] = paymentOption;
            }
        },
        getIcon(paymentID) {
            switch (paymentID) {
                case 'IUGU_PIX':
                    return 'icon-pix';
                case 'BANK_SLIP':
                    return 'icon-boleto';
                case 'IUGU_CREDIT_CARD':
                    return 'icon-credit-card';
                default:
                    return '';
            }
        },

        removeBlockedCustomer(email) {
            return this.$store.dispatch('checkout/removeBlockedCustomer', email);
        }
    },

    watch: {
        bankSlipType(newVal) {
            console.log("🚀 ~ bankSlipType ~ newVal:", newVal)
            this.paymentMethodModel['dwfrm_billing_paymentMethod'] = this.bankSlipType;
        },
        maxAttemptError(newVal) {
            if(newVal) {
                this.updateSelectedPaymentMethod('IUGU_PIX')
                setTimeout(async () => {

                    var successfullyRemoved = await this.removeBlockedCustomer(this.addressModel.dwfrm_customer_email)
                    if(successfullyRemoved.success) {
                        this.$store.commit('checkout/setMaxAttemptError', false);
                    }
                }, this.maxAttemptTimeout * 60000);
            }
        }
    }
}

</script>


<style>
    li.nav-item.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
</style>