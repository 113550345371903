var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order-total-summary" },
    [
      _c("div", { staticClass: "row leading-lines subtotal-item" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 end-lines" }, [
          _c("p", { staticClass: "text-right" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.totals.totalWithoutDiscount.formatted)),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row leading-lines coupomCheckout" }, [
        _c(
          "div",
          { staticClass: "coupons-and-promos" },
          [_c("coupon-display")],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row leading-lines order-discount",
          class:
            _vm.totals.grandTotalDiscount.value > 0
              ? ""
              : "hide-order-discount",
        },
        [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "col-6 end-lines" }, [
            _c("p", { staticClass: "text-right" }, [
              _c("span", { staticClass: "order-discount-total" }, [
                _vm._v("- " + _vm._s(_vm.totals.grandTotalDiscount.formatted)),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row leading-lines shipping-item" }, [
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 end-lines" }, [
          _c("p", { staticClass: "text-right" }, [
            _c(
              "span",
              {
                staticClass: "shipping-total-cost",
                class: {
                  "text-green":
                    _vm.totals.totalShippingCost.formatted.trim() === "Grátis",
                },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.totals.totalShippingCost.formatted) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.totals.shippingLevelDiscountTotal.value !== 0 &&
      _vm.totals.totalShippingCost.value !== 0
        ? [
            _c(
              "div",
              {
                staticClass: "row leading-lines shipping-discount",
                class:
                  _vm.totals.shippingLevelDiscountTotal.value === 0
                    ? "hide-shipping-discount"
                    : "",
              },
              [
                _vm._m(3),
                _vm._v(" "),
                _c("div", { staticClass: "col-6 end-lines" }, [
                  _c("p", { staticClass: "text-right" }, [
                    _c("span", { staticClass: "shipping-discount-total" }, [
                      _vm._v(
                        "- " +
                          _vm._s(
                            _vm.totals.shippingLevelDiscountTotal.formatted
                          )
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row leading-lines" }, [
        _vm._m(4),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 end-lines grandTotal" }, [
          _c("p", { staticClass: "text-right" }, [
            _c(
              "span",
              {
                staticClass: "sz-text--large bold grand-total grand-total-sum",
              },
              [_vm._v(_vm._s(_vm.totals.grandTotal.formatted))]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.addressModel.dwfrm_shipping_shippingAddress_addressFields_address1
        ? _c("div", { staticClass: "row leading-lines" }, [_vm._m(5)])
        : _vm._e(),
      _vm._v(" "),
      _vm.addressModel.dwfrm_shipping_shippingAddress_addressFields_address1
        ? _c("div", { staticClass: "row leading-lines" }, [
            _c("div", { staticClass: "col-12 start-lines" }, [
              _c("p", [
                _c("img", {
                  staticStyle: {
                    width: "20px",
                    height: "20px",
                    "margin-bottom": "4px",
                  },
                  attrs: {
                    src: _vm.iconMyAddress,
                    title: "Ícone do endereço",
                    alt: "Ícone do endereço",
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "17px !important",
                      "text-decoration": "underline !important",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.addressModel
                            .dwfrm_shipping_shippingAddress_addressFields_address1
                        ) +
                        ", " +
                        _vm._s(
                          _vm.addressModel
                            .dwfrm_shipping_shippingAddress_addressFields_city
                        ) +
                        " - " +
                        _vm._s(
                          _vm.addressModel
                            .dwfrm_shipping_shippingAddress_addressFields_states_stateCode
                        ) +
                        "\n                "
                    ),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6 start-lines" }, [
      _c("p", { staticClass: "order-receipt-label subTotal" }, [
        _c("span", { staticClass: "subTotal" }, [_vm._v("Subtotal")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6 start-lines" }, [
      _c("p", { staticClass: "order-receipt-label" }, [
        _c("span", [_vm._v("Desconto")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6 start-lines" }, [
      _c("p", { staticClass: "order-receipt-label" }, [
        _c("span", [_vm._v("Frete")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6 start-lines" }, [
      _c("p", { staticClass: "order-receipt-label" }, [
        _c("span", [_vm._v("Desconto do Frete")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6 start-lines grandTotal" }, [
      _c("p", { staticClass: "order-receipt-label grandTotal" }, [
        _c("span", [_vm._v("Total")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-6 start-lines grandTotal" }, [
      _c("p", { staticClass: "order-receipt-label grandTotal" }, [
        _c("span", [_vm._v("Entregando para:")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }