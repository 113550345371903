var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMobile
    ? _c(
        "div",
        { staticClass: "summary summary-mobile" },
        [
          _vm.showTitle === "true"
            ? [
                _c("div", { staticClass: "summary__title" }, [
                  _c("h1", [_vm._v(_vm._s(_vm.productParsed.productName))]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "(Código item: " + _vm._s(_vm.productParsed.id) + ")"
                    ),
                  ]),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showTitle === "false"
            ? [
                _c("div", { staticClass: "price" }, [
                  _c(
                    "span",
                    { staticClass: "strike-through list" },
                    [
                      _vm.productParsed.promo
                        ? [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.productParsed.promo.defaultPriceFormatted
                                ) +
                                "\n        "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _vm.productParsed.price
                  ? _c("div", { staticClass: "summary__price" }, [
                      _c("p", [_vm._v("A partir de:")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "price_box" }, [
                        _vm.productParsed.promo
                          ? _c("span", { staticClass: "price" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.productParsed.promo
                                    .promotionPriceFormatted
                                )
                              ),
                            ])
                          : _c("span", { staticClass: "price" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.productParsed.price.min
                                    ? _vm.productParsed.price.min.sales
                                        .formatted
                                    : _vm.productParsed.price.sales.formatted
                                )
                              ),
                            ]),
                        _vm._v(" "),
                        _vm.productParsed.promo
                          ? _c("span", { staticClass: "promotion_tag" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.productParsed.promo.promotionPercentage
                                ) + "% OFF"
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.productParsed.available
                  ? [
                      _c("product-quantity", {
                        attrs: {
                          use: "PDP",
                          showQnt: true,
                          id: _vm.productParsed.id,
                          uuid: _vm.productParsed.uuid,
                          quantity: _vm.productParsed.quantity,
                          uuidLineItem: _vm.productParsed.uuidLineItem,
                          productAvailable: _vm.productParsed.available,
                          urlAddCart: _vm.urlAddCart,
                          urlUpdateQnt: _vm.urlUpdateQnt,
                          urlRemoveProduct: _vm.urlRemoveProduct,
                        },
                      }),
                    ]
                  : [
                      _c(
                        "p",
                        { staticClass: "summary__quantity__unavailable" },
                        [_vm._v("Produto Indisponível")]
                      ),
                    ],
                _vm._v(" "),
                _c("div", { staticClass: "summary__description" }, [
                  _c("h2", { staticClass: "summary_title_description" }, [
                    _vm._v("Descrição do produto"),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.productParsed.shortDescription),
                    },
                  }),
                ]),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }