'use strict';

const { add } = require("lodash");

var updateMiniCart = true;
var isMiniCartFullyOpen = false;

function resetBodyStyles() {
    setTimeout(() => {
        console.log("🚀 ~ resetBodyStyles")
        $('body').css({
            'overflow': 'unset',
            'position': 'unset',
            'width': 'unset',
        });
        $('html').css('overflow', '');
        $('.modal-backdrop').remove();
    }, 300);
}

function validateMiniCartFooterModalStatus() {
    var count = parseInt($('.minicart .minicart-quantity').text(), 10);
    if(count===0) {
        console.log("🚀 ~ validateMiniCartFooterModalStatus ~ miniCartHide")
        miniCartHide()
        resetBodyStyles()
    }
}

function miniCartHide() {
    $('#minicart').modal('hide');
    resetBodyStyles();
}

function addScrolltags() {
    const $slider = $('.minicart-tags-scroll');
    let isDown = false;
    let startX;
    let scrollLeft;

    $slider.on('mousedown', function(e) {
        isDown = true;
        $slider.addClass('active');
        startX = e.pageX - $slider.offset().left;
        scrollLeft = $slider.scrollLeft();
    });

    $slider.on('mouseleave mouseup', function() {
        isDown = false;
        $slider.removeClass('active');
    });

    $slider.on('mousemove', function(e) {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - $slider.offset().left;
        const walk = (x - startX) * 3;
        $slider.scrollLeft(scrollLeft - walk);
    });

    if($(window).width() <= 992 && $('.minicart-tags').length){
        $('#minicart .modal-body').css('max-height', 'calc(100svh - 300px)' )
    }
}

// full modal shown
function miniCartFullShow(){
    console.log("🚀 ~ miniCartFullShow")
    console.log("🚀 ~ isMiniCartFullyOpen:", isMiniCartFullyOpen)
    isMiniCartFullyOpen = true;
    var $modalDialog = $('#minicart .modal-dialog.modal-dialog-scrollable');
    var $modalBg = $('#minicart.modal');

    $modalDialog.addClass('show');
    $modalBg.css('height', '100%');
    $('body.modal-open').css('overflow', 'hidden');
    $('body.modal-open').css('position', 'fixed');
    $('body.modal-open').css('width', '100%');

    if($(window).width() <= 992){
        //datalayer trigger view_cart mobile
        var view_cart = $('input[name="view_cart"]').val();
        $('body').trigger('dataLayerPush:event', JSON.parse(view_cart));
    }

    console.log("🚀 ~ isMiniCartFullyOpen:", isMiniCartFullyOpen)
    console.log("🚀 ~ end miniCartFullShow")
}

function miniCartFullHide(){
    console.log("🚀 ~ miniCartFullHide");
    var $modalDialog = $('#minicart .modal-dialog.modal-dialog-scrollable');
    var $modalBg = $('#minicart.modal');

    $modalDialog.removeClass('show');
    $modalBg.css('height', '0');

    isMiniCartFullyOpen = false;

    resetBodyStyles();

    setTimeout(() => {
        $('.minicart .miniCart-wrap').addClass('show');
        $('#minicart').modal('show');
        $('#minicart')[0].offsetHeight;
    }, 100);
}

// only footer modal shown
function miniCartShow() {
    console.log("🚀 ~ miniCartShow")
    var url = $('.minicart').data('action-url');
    var count = parseInt($('.minicart .minicart-quantity').text(), 10);

    if (count !== 0) {

        if (!updateMiniCart) {
            console.log("🚀 ~ miniCartShow ~ updateMiniCart if control")
            $('.minicart .miniCart-wrap').addClass('show');

            var interval = setInterval(() => {
                var backdrop = $(".modal-backdrop");
                if (backdrop.length > 0) {
                    backdrop.remove(); 
                    clearInterval(interval);
                }
            }, 20);
            return;
        }

        $('.minicart .miniCart-wrap').addClass('show');
        if($(window).width() > 992){
            $('body').spinner().start();
        }
        $.get(url, function (data) {
            $('.minicart .miniCart-wrap').empty();
            $('.minicart .miniCart-wrap').append(data);
            updateMiniCart = false;
            let viewportHeight = window.innerHeight;
            let modal = document.querySelector('#minicart .modal-content');
            modal.style.height = viewportHeight + 'px';
            modal.style.maxHeight = viewportHeight + 'px';
            console.log("🚀 ~ minicart: $('#minicart').modal('show')")
            $('#minicart').modal('show');
            $(".modal-backdrop").remove();

            if($(window).width() <= 992){
                console.log("🚀 ~ miniCartShow ~ $('body.modal-open').css('overflow', 'unset')")
                $('body.modal-open').css('overflow', 'unset');
                $('body.modal-open').css('position', 'unset');
                $('body.modal-open').css('width', 'unset');
            }


            addScrolltags()
            if($(window).width() > 992){
                $.spinner().stop();
            }
        });
    }

    if ($('.coupon-code').length > 0) {
        $('.couponCodeBox').css('display', 'none');
    }

    isMiniCartFullyOpen = false;
}

module.exports = function () {
    $('.minicart').on('count:update', function (event, count) {

        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
            document.querySelector('.minicart').dispatchEvent(new CustomEvent('count:update', { detail: count }));
        }
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        $('.couponCodeBox').css('display', 'flex');
    });

    $('body').on('click', '.js-btn-see-cart', function (e) {
        miniCartFullShow()
    });

    //just mobile behavior
    $(document).on('hidden.bs.modal','#minicart', function () {
        console.log("🚀 ~ hidden.bs.modal ~ isMiniCartFullyOpen:", isMiniCartFullyOpen);
        if($(window).width() <= 992){
            if(isMiniCartFullyOpen){
                miniCartFullHide();
            }
        }
    });

    $('body').on('miniCartOpenMobile:event', function (event, element) {
        miniCartShow();
    });

    $('body').on('miniCartCloseMobile:event', function (event, element) {
        if($(window).width() <= 992){
            console.log("🚀 ~ miniCartCloseMobile")
            validateMiniCartFooterModalStatus();
        }
    });

    $('.promo-code-form-minicart').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form-minicart .form-control').addClass('is-invalid');
            $('.promo-code-form-minicart .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form-minicart');
        $('.promo-code-form-minicart .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form-minicart .form-control').addClass('is-invalid');
                    $('.promo-code-form-minicart .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    $('.couponCodeBox').css('display', 'none');
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $('body').trigger('promotion:success', data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .miniCart-wrap').removeClass('show');
        }
    });

    $('body').on('change', '.minicart .quantity', function () {

        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });

    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });

};

//document ready
$(document).ready(function () {
    if($(window).width() <= 992){
        miniCartShow();
        $('.minicart-total').on('click touchstart', function (e) {
            miniCartFullShow()
            e.preventDefault()
        });
    } else {
        $('.minicart-total').on('click touchstart', function () {
            miniCartShow()
        });
    }
});
