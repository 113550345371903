var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.totals.discounts, function (discount) {
        return [
          discount.type === "coupon"
            ? [
                _c(
                  "div",
                  {
                    class:
                      "coupon-price-adjustment coupon-uuid-" + discount.UUID,
                    attrs: { "data-uuid": discount.UUID },
                  },
                  [
                    discount.applied
                      ? _c("span", { staticClass: "coupon-applied" }, [
                          _vm._v("Cupom ativo"),
                        ])
                      : _c("span", { staticClass: "coupon-not-applied" }, [
                          _vm._v("Não aplicado"),
                        ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "coupon-code" }, [
                      _vm._v(
                        _vm._s(discount.couponCode) + "\n                    "
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "float-right btn-remove-coupon",
                          attrs: {
                            type: "button",
                            "data-code": discount.couponCode,
                            "aria-label": "Remover cupom" + discount.couponCode,
                            "data-toggle": "modal",
                            "data-target": "#alertRemoveCouponModal",
                            "data-uuid": discount.UUID,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setCouponUUID(
                                discount.UUID,
                                discount.couponCode
                              )
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass: "removeCoupomIcon icon-close",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.couponError === true && _vm.removeUUID === discount.UUID
                  ? _c("p", { staticClass: "errorRemoveCoupon" }, [
                      _vm._v(" " + _vm._s(_vm.couponErrorMsg) + " "),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }