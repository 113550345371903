var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    attrs: {
      modalId: "modalShareInfo",
      size: "md",
      showCloseButton: false,
      isStatic: true,
      startOpen: _vm.isShareQuotation ? true : false,
    },
    on: { "hande:close": _vm.handleClose },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _c(
              "h5",
              {
                staticClass: "modal-title bold sz-heading--medium pb-0",
                attrs: { id: "modalShareInfoLabel" },
              },
              [_vm._v("Bem-vindo ao Suprijá!")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _vm.addressModal.city && _vm.addressModal.postalCode
              ? _c("p", [
                  _vm._v(
                    "\n            A cotação que você recebeu contém produtos de "
                  ),
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.addressModal.city) +
                        " | " +
                        _vm._s(_vm.addressModal.postalCode)
                    ),
                  ]),
                  _vm._v(".\n        "),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.logged
              ? [
                  _c("p", [
                    _c("b", [
                      _vm._v("Acesse a sua conta para concluir o pedido!"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.showForm
                    ? _c(
                        "div",
                        { attrs: { id: "showForm" } },
                        [_c("login-form"), _vm._v(" "), _c("login-oauth")],
                        1
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.logged,
                    expression: "logged",
                  },
                ],
                staticClass: "btn button button-secondary",
                staticStyle: { width: "100%" },
                on: { click: _vm.handleClose },
              },
              [_vm._v("Visualizar minha cotação")]
            ),
            _vm._v(" "),
            !_vm.logged
              ? [
                  !_vm.showForm
                    ? _c("div", { staticClass: "cta-body" }, [
                        _c(
                          "button",
                          {
                            staticClass: "login-option btn",
                            attrs: { type: "button" },
                            on: { click: _vm.showFormLogin },
                          },
                          [_vm._v("Fazer login")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "register-option btn",
                            attrs: {
                              type: "button",
                              "data-toggle": "modal",
                              "data-target": "#registerCheckout",
                            },
                            on: { click: _vm.handleClose },
                          },
                          [
                            _vm._v(
                              "\n                    Cadastrar-se\n                "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "login-divider" }, [
                    _c("span", [_vm._v("ou")]),
                  ]),
                  _vm._v(" "),
                  _vm.showForm
                    ? _c(
                        "button",
                        {
                          staticClass: "register-option btn",
                          attrs: {
                            type: "button",
                            "data-toggle": "modal",
                            "data-target": "#registerCheckout",
                          },
                          on: { click: _vm.handleClose },
                        },
                        [_vm._v("\n                Cadastrar-se\n            ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn-guest",
                      attrs: { type: "button" },
                      on: { click: _vm.handleClose },
                    },
                    [
                      _vm._v(
                        "\n                Continuar como convidado\n            "
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }