<template>
    <modal
        modalId="alertRemoveCouponModal"
        size="md"
        :showCloseButton="true"
        :isStatic="true"
        :startOpen="false"
        @hande:close="handleClose"
    >
        <template v-slot:title>
           Remover cupom?
        </template>

        <template v-slot:body>
            <p>Tem certeza que deseja remover o seguinte cupom do carrinho?</p>
            <p class="coupon-to-remove">{{ coupon.couponCode }}</p>
        </template>

        <template v-slot:footer>
            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary delete-coupon-confirmation-modal-btn"
                    data-dismiss="modal"
                    @click="removeCoupon()">
                    Sim
            </button>
        </template>
    </modal>
</template>


<script>
    import { mapGetters } from "vuex";
    import Modal from '../Modal';

    export default {

        components: {
            Modal
        },


        computed: {
            ...mapGetters('checkout', {
                coupon: "coupon",
            }),
        },


        methods: {
            handleClose() {
                $('#alertRemoveCouponModal').modal('hide');
            },

            async removeCoupon() {
                this.$root.$refs.CouponDisplay.couponErrorMsg = '';
                this.$root.$refs.CouponDisplay.couponError = false;

                if(this.coupon.couponUuid){
                    const resultRemoveCoupon = await this.$store.dispatch('checkout/removeCoupon', this.coupon.couponUuid);

                    if (resultRemoveCoupon.hasOwnProperty('error') && resultRemoveCoupon.error) {
                        this.$root.$refs.CouponDisplay.couponErrorMsg = resultRemoveCoupon.errorMessage;
                        this.$root.$refs.CouponDisplay.couponError = true;
                    }else{
                        this.$root.$refs.Coupon.resetCouponField();
                    }

                    this.$root.$refs.CouponDisplay.removeUUID = '';
                    this.$root.$refs.CouponDisplay.couponCode = '';
                }
            }
        }
    }
</script>
