var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.itemsParsed.value != false,
          expression: "itemsParsed.value != false",
        },
      ],
      staticClass: "box_wrap",
    },
    [
      _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.currentTitle))]),
      _vm._v(" "),
      _c("div", { staticClass: "pdp-buy-together" }, [
        _c(
          "div",
          { staticClass: "pdp-buy-together__items" },
          [
            _c("product-card", {
              attrs: { product: _vm.productParsed, showBtn: false },
            }),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("product-card", {
              attrs: { product: _vm.itemsParsed.product, showBtn: false },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pdp-buy-together-box" },
          [
            _c("span", { staticClass: "price" }, [
              _c("b", [_vm._v("Preço total:")]),
              _vm._v(" " + _vm._s(_vm.itemsParsed.value)),
            ]),
            _vm._v(" "),
            _c("product-add", {
              attrs: {
                pidsobj: _vm.idObj,
                productAvailable: _vm.productParsed.available,
                urlAddCart: _vm.urlAddCart,
                urlUpdateQnt: _vm.urlUpdateQnt,
                urlRemoveProduct: _vm.urlRemoveProduct,
                use: "PDP",
              },
            }),
            _vm._v(" "),
            _vm._m(1),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pdp-buy-together__icon-plus" }, [
      _c("i", { staticClass: "icon-icon-more" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pdp-alert" }, [
      _c("i", { staticClass: "icon-info-circle-fill" }),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Estes itens poderão ser enviados e vendidos por vendedores diferentes."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }