var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "breadcrumb__wrap",
      attrs: { role: "navigation", "aria-label": "Breadcrumb" },
    },
    [
      _c(
        "ol",
        {
          staticClass: "breadcrumb__list",
          attrs: {
            itemprop: "breadcrumblist",
            itemscope: "itemscope",
            itemtype: "https://schema.org/BreadcrumbList",
          },
        },
        [
          _c(
            "li",
            {
              staticClass: "breadcrumb-item",
              attrs: {
                itemprop: "itemListElement",
                itemscope: "",
                itemtype: "https://schema.org/ListItem",
              },
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.breadcrumbsParsed[0].url,
                    "aria-current": "page",
                    itemprop: "item",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.breadcrumbsParsed[0].htmlValue) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("meta", { attrs: { itemprop: "position", content: "1" } }),
              _vm._v(" "),
              _c("meta", {
                attrs: {
                  itemprop: "name",
                  content: _vm.breadcrumbsParsed[0].htmlValue,
                },
              }),
            ]
          ),
          _vm._v(" "),
          _vm.breadcrumbsParsed.length > 2 && !_vm.expanded
            ? _c(
                "span",
                {
                  staticClass: "breadcrumb-item",
                  on: { click: _vm.expandBreadcrumbs },
                },
                [_vm._v("\n      ...\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.visibleBreadcrumbs, function (breadcrumbValue, index) {
            return _vm.expanded
              ? _c(
                  "li",
                  {
                    key: index,
                    staticClass: "breadcrumb-item",
                    attrs: {
                      itemprop: "itemListElement",
                      itemscope: "",
                      itemtype: "https://schema.org/ListItem",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: breadcrumbValue.url,
                          title: breadcrumbValue.htmlValue,
                          "aria-current": "page",
                          itemprop: "item",
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(breadcrumbValue.htmlValue) +
                            "\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("meta", {
                      attrs: {
                        itemprop: "position",
                        content: (index + 2).toFixed(0),
                      },
                    }),
                    _vm._v(" "),
                    _c("meta", {
                      attrs: {
                        itemprop: "name",
                        content: breadcrumbValue.htmlValue,
                      },
                    }),
                  ]
                )
              : _vm._e()
          }),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "breadcrumb-item",
              attrs: {
                itemprop: "itemListElement",
                itemscope: "",
                itemtype: "https://schema.org/ListItem",
              },
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.breadcrumbsParsed[0].url,
                    title:
                      _vm.breadcrumbsParsed[_vm.breadcrumbsParsed.length - 1]
                        .htmlValue,
                    "aria-current": "page",
                    itemprop: "item",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.breadcrumbsParsed[_vm.breadcrumbsParsed.length - 1]
                          .htmlValue
                      ) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("meta", {
                attrs: {
                  itemprop: "position",
                  content: _vm.breadcrumbsParsed.length.toFixed(0),
                },
              }),
              _vm._v(" "),
              _c("meta", {
                attrs: {
                  itemprop: "name",
                  content:
                    _vm.breadcrumbsParsed[_vm.breadcrumbsParsed.length - 1]
                      .htmlValue,
                },
              }),
            ]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }