<template>
  <div v-if="isLargeScreen" ref="summaryBox" class="summary box">
    <div class="summary__title">
      <h1>{{ productParsed.productName }}</h1>
      <small>(Código item: {{ productParsed.id }})</small>
    </div>
    <div class="price">
        <span class="strike-through list">
          <template v-if="productParsed.promo">
            <!-- {{ productParsed.price.min ? productParsed.price.min.sales.formatted : productParsed.price.sales.formatted }} -->
            {{ productParsed.promo.defaultPriceFormatted }}
          </template>
        </span>
    </div>
    <div class="summary__price">
      <p>A partir de:</p>
      <p class="price_box">
        <span v-if="productParsed.promo" class="price">{{ productParsed.promo.promotionPriceFormatted }}</span>
        <span v-else class="price">{{ productParsed.price.min ? productParsed.price.min.sales.formatted : productParsed.price.sales.formatted }}</span>

       <span v-if="productParsed.promo" class="promotion_tag">{{productParsed.promo.promotionPercentage}}% OFF</span>
      </p>
    </div>

    <template v-if="productParsed.available">
      <product-quantity
        use="PDP"
        :showQnt="true"
        :id="productParsed.id"
        :uuid="productParsed.uuid"
        :quantity="productParsed.quantity"
        :uuidLineItem="productParsed.uuidLineItem"
        :productAvailable="productParsed.available"
        :urlAddCart="urlAddCart"
        :urlUpdateQnt="urlUpdateQnt"
        :urlRemoveProduct="urlRemoveProduct"
      >
      </product-quantity>
    </template>
    <template v-else>
      <p class="summary__quantity__unavailable">Produto Indisponível</p>
    </template>

    <div class="summary__description">
      <h2 class="summary_title_description">Descrição do produto</h2>
      <div v-html="productParsed.shortDescription"></div>
    </div>

  </div>
</template>

<script>
import ProductQuantity from './ProductQuantity';

export default {
  props: ['product', 'urlAddCart', 'urlUpdateQnt', 'urlRemoveProduct'],
  components: {
    ProductQuantity
  },
  created() {
      this.productParsed = JSON.parse(this.product);
  },
  data() {
    return {
      productParsed: null,
      isLargeScreen: window.innerWidth >= 768
    };
  },
  mounted() {
    window.addEventListener('resize', this.checkWindowSize);
    if (this.isLargeScreen) {
      this.$nextTick(() => {
        if (this.$refs.summaryBox) {
          window.addEventListener('scroll', this.handleScroll);
        }
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWindowSize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    checkWindowSize() {
      this.isLargeScreen = window.innerWidth >= 768;
      if (this.isLargeScreen) {
        this.$nextTick(() => {
          if (this.$refs.summaryBox) {
            window.addEventListener('scroll', this.handleScroll);
          }
        });
      } else {
        window.removeEventListener('scroll', this.handleScroll);
      }
    },
    handleScroll() {
      const summaryBox = this.$refs.summaryBox;
      var footer = document.getElementById('footercontent')

      const summaryBoxHeight = summaryBox.offsetHeight;
      const footerOffset = footer.offsetTop;
      const footerHeight = footer.offsetHeight;

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const summaryBoxOffset = scrollTop + summaryBoxHeight + 20; // 20 é a margem do topo

      if (summaryBoxOffset >= footerOffset) {
        summaryBox.style.position = 'absolute';
        summaryBox.style.top = (footerOffset - footerHeight - summaryBoxHeight + 30) + 'px';
      } else {
        summaryBox.style.position = 'sticky';
        summaryBox.style.top = '135px';
      }
    }
  }
};
</script>

<style>
  .summary_title_description{
    margin-bottom: 10px;
    display: inline-block;
  }

  .summary__quantity__unavailable {
    position: relative;
    top: 0;
    margin-top: 0;
    float: right;
    padding: 10px;
    border-radius: 5px;
    background: #efefef;
  }
  .summary{
    .quantityAndShop {
    height: 40px;
    }
  } 

</style>
