const vueAppEl = document.querySelector('#vueApp') || document.querySelector('#vueAppCheckout');
const dataStatic = vueAppEl.dataset.staticpath ? vueAppEl.dataset.staticpath : "";
var dataPath;

if (typeof(vueAppEl) != 'undefined' && vueAppEl != null) {
    dataPath = vueAppEl.dataset.path != 'null' ? vueAppEl.dataset.path : '';
}

export default {
    //URL Utils
    "urlStatic": dataStatic,
    "urlSite"  : dataPath,

    //Home
    "home": {
        "show": `${dataPath}Home-Show`,
        "errorNotFound": `${dataPath}Home-ErrorNotFound`
    },

    //Default
    "default": {
        "start": `${dataPath}Default-Start`,
        "offline": `${dataPath}Default-Offline`
    },

    //Account
    "account": {
        "logincheckout": `${dataPath}Account-Login?rurl=2&step=2`,
        "passwordResetDialogForm": `${dataPath}Account-PasswordResetDialogForm`,
    },

    //Login
    "login": {
        "oAuthLogin": `${dataPath}Login-OAuthLogin?oauthProvider=Google&oauthLoginTargetEndPoint=1`,
        "show": `${dataPath}Login-Show?action=register`,
    },

    //SearchServices
    "searchServices": {
       "getSuggestions": `${dataPath}SearchServices-GetSuggestions`,
    },

    //Checkout
    "checkout": {
        "begin": `${dataPath}Checkout-Begin`,
        "validateUser": `${dataPath}Checkout-ValidateUser`,
        "confirmShipping": `${dataPath}Checkout-ConfirmShipping`,
    },

    //Checkout Services
    "checkoutServices": {
        "submitPayment": `${dataPath}CheckoutServices-SubmitPayment`,
        "placeOrder": `${dataPath}CheckoutServices-PlaceOrder`,
        "removeBlockedCustomer": `${dataPath}CheckoutServices-RemoveBlockedCustomer`
    },

    //Quotation
    "quotation": {
        "select": `${dataPath}Quotation-Select`,
        "share": `${dataPath}Quotation-Share`,
    },

    //Cart
    "cart": {
        "addProduct": `${dataPath}Cart-AddProduct`,
        "get": `${dataPath}Cart-Get`,
        "removeProductLineItem": `${dataPath}Cart-RemoveProductLineItem`,
        "updateQuantity": `${dataPath}Cart-UpdateQuantity`,
        "addCoupon": `${dataPath}Cart-AddCoupon`,
        "removeCoupon": `${dataPath}Cart-RemoveCouponLineItem`
    },

}
