<template>
    <div style="position: relative;">
        <div class="share-quoatation-container" @click="toggleExpanded" :class="{disabled : disabled}">
            <span>
                <i v-if="expanded" class="icon-close"></i>
                <i v-else class="icon-share"></i>
            </span>
            <p class="d-none d-lg-block mb-0">Compartilhar cotação</p>
        </div>
        <div v-show="expanded" class="share-quotation-links-modal">
            <ul class="nav" role="menu">
                <p class="d-lg-none nav-item mb-0 title">Compartilhar cotação</p>
                <li class="nav-item"><a class="w-100" :href="quotationLinks ? quotationLinks.whatsapp : ''" target="_blank"><i class="icon-whatsapp"></i> WhatsApp</a></li>
                <li class="nav-item" @click="handleCopyLink"><i class="icon-link"></i> Copiar Link</li>
                <li class="nav-item"><a class="w-100" :href="quotationLinks ? quotationLinks.email : ''" target="_blank"><i class="icon-email"></i> E-mail</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import routes from '../../../api/routes';

export default {
    components: {},
    data() {
        return {
            expanded: false,
            disabled: false,
        }
    },
    methods: {
        async toggleExpanded(){
            if(Object.keys(this.quotationLinks).length === 0) {
                var result = await this.$store.dispatch('checkout/quotationShare');
                if(result.success) this.toggleQuotationShareModal()
                else this.toggleDisabledQuotationShare()
            } else {
                this.toggleQuotationShareModal()
            }

        },
        toggleQuotationShareModal() {
            this.expanded = !this.expanded;
        },
        toggleDisabledQuotationShare(){
            this.$set(this, 'disabled', !this.disabled);
        },
        handleCopyLink(){
            navigator.clipboard.writeText(this.quotationLinks.copylink)
            this.toggleExpanded()
        },
    },
    computed: {
        ...mapGetters('checkout', {
            quotationLinks: 'quotationLinks'
        }),
    },


}
</script>
